import React, { ReactElement } from "react"

interface Props {
  text: string
  align?: "left" | "center" | "right" | "justify"
}

function Heading1({ text, align }: Props): ReactElement {
  return (
    <h1
      style={{ borderBottom: "1px solid #CCCCCC" }}
      className={
        " relative text-xl md:text-4xl font-bold pb-4 " +
        (align === "left"
          ? "text-left"
          : align === "center"
          ? "text-center"
          : align === "right"
          ? "text-right"
          : align === "justify"
          ? "text-justify"
          : "")
      }
    >
      <div>{text}</div>
      <div
        className={
          "flex -mb-4 " +
          (align === "center"
            ? "justify-center"
            : align === "right"
            ? "justify-end"
            : "")
        }
      >
        <div className={"w-1/4 bg-teal-700 "} style={{ height: "7px" }} />
      </div>
    </h1>
  )
}

export default Heading1
