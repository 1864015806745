export default {
  heading: "Terms & Conditions",
  list: [
    {
      title: "Welcome to Loan-Tree",
      html: `Privacy Act 1993 – Loan-Tree is committed to protecting your privacy and maintaining security of the information you provide to us. We abide by the laws relating to privacy and safeguarding of information.`,
    },
    {
      title: "PRIVACY ACT 1993 – PERSONAL INFORMATION NOTICE AND DECLARATION",
      html: `I/We declare that:

      I/We have read and understood this application and certify that the information provided is true and correct in all respects and that I/We are no less than 18 years of age and I/We am/are not an undischarged bankrupt.
      
      I have also disclosed any other names I am known under.
      
      I/we are aware that if we have deliberately provided false information in this application, I/we could face fraud or dishonesty charges in the courts.`,
    },
    {
      title: "PRIVACY ACT ACKNOWLEDGEMENT AND AUTHORISATION",
      html: `You authorise Loan-Tree to send this application to any third party (the Finance Company) and agree that either Loan-Tree or the Finance Company may do the following:

      You understand that Loan-Tree / the Finance Company are asking for personal information about you to use Equifax, Dun & Bradstreet or Centrix credit reporting service to credit check you.
      
      You understand that Equifax, Dun & Bradstreet or Centric will give us personal information about you.
      
      You understand that we will give your personal information to Equifax, Dun & Bradstreet or Centrix and they will hold that information and use it to provide their credit reporting service.
      
      You understand that when other Equifax, Dun & Bradstreet or Centrix customers use the Equifax, Dun & Bradstreet or Centrix credit reporting service, Equifax, Dun & Bradstreet or Centrix may give that information to them.
      
      You understand that we may use Equifax, Dun & Bradstreet Centrix credit reporting services in the future for purposes related to the provision of credit to you. This may include use of Equifax, Dun & Bradstreet or Centrix monitoring services to include updates if information about you changes.
      
      Loan-Tree / the Finance Company will use the information they collect about you to assess your Application and verify your identity. We/they may disclose to, and obtain from, Credit Reporting and Government Agencies, other Credit Providers and Insurers, personal information about you for these purposes.
      
      Pursuant to the Privacy Act 1993 I authorise Loan-Tree or the Finance Company to contact Equifax, Dun & Bradstreet or Centrix, Credit Providers, Finance Companies, my Employer, Accountant, Bank, Solicitor or any other source including Government agencies, to obtain, check and exchange (both now and in future) such personal financial and commercial information for the purposes of considering this or any subsequent application. The protection and administration of any loan arising out of this application (both now and in the future) and to assist in the enforcement of any agreement between me and Loan-Tree or the Finance Company. I understand I have the right to know who is collecting and holding information and also the right to have access to it and to correct if it is wrong.
      
      I give permission to the FINANCE COMPANY or any related finance company to send me/us details of other products and services from time to time.`,
    },
  ],
}
